body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("/src/fonts/Roboto-Thin.ttf");
}

@font-face {
  font-family: "Lemon Milk";
  src: url("/src/fonts/LEMONMILK-Light.otf");
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.rotating-table {
  border-collapse: collapse;
}

td {
  width: 25px;
  height: 25px;
  border: none;
  background-color: var(--no-color);
}

#paper-slabs {
  opacity: 0;
  transition:
    opacity 1s,
    font-size 1s;
  width: 175px;
  text-align: center;
  text-transform: uppercase;
  height: 87px;
  bottom: -50px;
  position: absolute;
}

#paper-slabs .first {
  font-size: 1.5em;
  font: 2em "Lemon Milk";
  letter-spacing: 0.5rem;
}

#paper-slabs .second {
  font-size: 1.5em;
  font: 1em "Roboto Mono";
  letter-spacing: 0.8rem;
}

/* css variables for colors */
:root {
  --no-color: #fff;
  --color-1-1: #f88b30;
  --color-2-2: #f66e2a;
  --color-2-1: #e8492e;
  --color-2-3: #7f79b2;
  --color-3-1: #f96e61;
  --color-3-2: #8e75ab;
  --color-3-3: #7f2ea5;
  --color-4-1: #f55a8c;
  --color-4-2: #ba52c9;
  --color-4-3: #4e51a1;
  --color-4-4: #812ea5;
  --color-5-1: #d3305b;
  --color-5-2: #ed4059;
  --color-5-3: #9b33b0;
  --color-5-4: #702b8f;
  --color-6-1: #ca58a6;
  --color-6-2: #f0508b;
  --color-6-3: #7069a9;
  --color-6-4: #1f94f1;
  --color-6-5: #b834a7;
  --color-6-6: #4e61b1;
  --color-6-7: #3a81d5;
  --color-7-1: #1ea3e0;
  --color-7-2: #1ea3e0;
  --color-7-3: #2ab8e9;
  --color-8-1: #209cd4;
  --color-8-2: #209cd4;
  --color-8-3: #45caf8;
  --color-9-1: #3ec6fb;
}

/* Set initial colors for each cell */

#cell-0-4 {
  background-color: var(--color-1-1);
}

#cell-1-2 {
  background-color: var(--color-2-1);
}
#cell-1-3 {
  background-color: var(--color-2-2);
}
#cell-1-5 {
  background-color: var(--color-2-3);
}

#cell-2-2 {
  background-color: var(--color-3-1);
}
#cell-2-3 {
  background-color: var(--color-3-2);
}
#cell-2-4 {
  background-color: var(--color-3-3);
}

#cell-3-1 {
  background-color: var(--color-4-1);
}
#cell-3-2 {
  background-color: var(--color-4-2);
}
#cell-3-3 {
  background-color: var(--color-4-3);
}
#cell-3-4 {
  background-color: var(--color-4-4);
}

#cell-4-1 {
  background-color: var(--color-5-1);
}
#cell-4-2 {
  background-color: var(--color-5-2);
}
#cell-4-3 {
  background-color: var(--color-5-3);
}
#cell-4-4 {
  background-color: var(--color-5-4);
}

#cell-5-0 {
  background-color: var(--color-6-1);
}
#cell-5-1 {
  background-color: var(--color-6-2);
}
#cell-5-2 {
  background-color: var(--color-6-3);
}
#cell-5-3 {
  background-color: var(--color-6-4);
}
#cell-5-4 {
  background-color: var(--color-6-5);
}
#cell-5-5 {
  background-color: var(--color-6-6);
}
#cell-5-6 {
  background-color: var(--color-6-7);
}

#cell-6-1 {
  background-color: var(--color-7-1);
}
#cell-6-2 {
  background-color: var(--color-7-2);
}
#cell-6-3 {
  background-color: var(--color-7-3);
}

#cell-7-1 {
  background-color: var(--color-8-1);
}
#cell-7-2 {
  background-color: var(--color-8-2);
}
#cell-7-3 {
  background-color: var(--color-8-3);
}

#cell-8-1 {
  background-color: var(--color-9-1);
}
